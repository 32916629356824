import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
	selector: 'inicio',
	templateUrl: './inicio.component.html'
})

export class InicioComponent implements OnInit{
	title = 'Estamos en Inicio';
  	public identity;
  	constructor(private _usuarioService: UsuarioService, private _router: Router){
  }
	ngOnInit(){
	    if(this._usuarioService.getToken() === null){
	      this.logout();
	    }
	}
  logout(){
  	localStorage.clear();
  	this.identity = null;
  	this._router.navigate(['/login']);
  }
}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { routing, appRoutingProviders } from './app.routing';

//guards
import { AdminGuard } from './services/admin.guard';
import { RoleGuard } from './services/role.guard';
//component
import { AppComponent } from './app.component';
import { InicioComponent} from './component/inicio/inicio.component';
import { AlumnosComponent} from './component/alumnos/alumnos.component';
import { CursoComponent} from './component/curso/curso.component';
import { LoginComponent} from './component/login/login.component';
import { RegistroComponent} from './component/registro/registro.component';
import { UsuarioEditComponent} from './component/usuario-edit/usuario-edit.component';
import { ListadoCursoIdComponent } from './component/listado-curso-id/listado-curso-id.component';
import { ListadoCursoIdUrlComponent } from './component/listado-cursos-id-url/listado-cursos-id-url.component';
import { FormularioComponent } from './component/formulario/formulario.component';
import { FormularioRespuestaComponent } from './component/formulario-respuesta/formulario-respuesta.component';
import { FormularioRespuestaVerComponent } from './component/formulario-respuesta-ver/formulario-respuesta-ver.component';
import { ListadoCursoIdUrlLeccionComponent } from './component/listado-curso-id-url-leccion/listado-curso-id-url-leccion.component';
import {LeccionEtapaComponent} from './component/leccion-etapa/leccion-etapa.component';
//Servicios
import { UsuarioService } from './services/usuario.service';
import { CursoService } from './services/curso.service';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    CursoComponent,
    LoginComponent,
    RegistroComponent,
    UsuarioEditComponent,
    AlumnosComponent,
    ListadoCursoIdComponent,
    ListadoCursoIdUrlComponent,
    FormularioComponent,
    FormularioRespuestaComponent,
    FormularioRespuestaVerComponent,
    ListadoCursoIdUrlLeccionComponent,
    LeccionEtapaComponent
  ],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    HttpModule,
  ],
  providers: [
  appRoutingProviders,
  UsuarioService,
  CursoService,
  AdminGuard,
  RoleGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CursoService } from '../../services/curso.service';

@Component({
	selector: 'listado-curso-id',
	templateUrl: './listado-curso-id.component.html'
})

export class ListadoCursoIdComponent implements OnInit{
	public cursos:string;
	public url_enviar:string;
	constructor(private _route: ActivatedRoute, private _router: Router, private _cursoService: CursoService){
	}	
	ngOnInit(){
		this.getListadoCursosId();
	}
	getListadoCursosId(){
		this._route.params.forEach((params: Params) =>{
			let url_curso = params['url_curso'];
			this._cursoService.getListadoCursosId(url_curso).subscribe(
				response => {
					if(response.cursos.url){
						this._router.navigate(['/curso']);
					}else{
						this.url_enviar = url_curso;
						this.cursos = response.cursos;
					}
				},
				error => {
					this._router.navigate(['/curso']);
				}
			);
		});
	}
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GLOBAL } from '../../services/global';
import { CursoService } from '../../services/curso.service';
import { Curso } from '../../models/curso';

@Component({
	selector: 'curso',
	templateUrl: './curso.component.html'
})

export class CursoComponent implements OnInit{
	public title:string;
	public cursos: Curso[];
	constructor(private _route: ActivatedRoute, private _router: Router, private _cursoService: CursoService){
		this.title =  'Listado de Cursos';
	}	
	ngOnInit(){
		this._cursoService.getCursos().subscribe(
			response => {
				if(!response.curso){

				}else{
					this.cursos = response.curso;
				}
			},
			error => {
				this._router.navigate(['/inicio']);
			}
		);
	}
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { GLOBAL } from '../../services/global';
import { UsuarioService } from '../../services/usuario.service';


@Component({
	selector: 'alumnos',
	templateUrl: './alumnos.component.html'
})

export class AlumnosComponent implements OnInit{
	public title:string;
	public usuario: Usuario;
	constructor(private _route: ActivatedRoute, private _router: Router, private _usuarioService: UsuarioService){
		this.title = 'Alumnos';
		this.usuario = new Usuario('','','','','','');	
	}
	ngOnInit(){
	}
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CursoService } from '../../services/curso.service';


@Component({
	selector: 'formulario',
	templateUrl: './formulario.component.html'
})

export class FormularioComponent implements OnInit{
	public formulario:string;
	public url_curso_a:string;
	public url_etapa_a:string;
	public url_formulario_c:string;
	public datos:string;
	constructor(private _route: ActivatedRoute, private _router: Router, private _cursoService: CursoService){
	}	
	ngOnInit(){
		this.getListadoFormulario();
	}
	getListadoFormulario(){
		this._route.params.forEach((params: Params) =>{
			let url_curso = params['url_curso'];
			let url_etapa = params['url_etapa'];
			let url_formulario = params['url_formulario'];
			this._cursoService.getFormulario(url_curso,url_etapa,url_formulario).subscribe(
				response => {
					if(!response.preguntas.formulario){
						this._router.navigate(['/curso']);
					}else{
						this.url_curso_a = url_curso;
						this.url_etapa_a = url_etapa;
						this.url_formulario_c = url_formulario;
						this.formulario = response.preguntas.formulario;
						this.datos = response.preguntas.datos;
					}
				},
				error => {
					this._router.navigate(['/curso']);
				}
			);
		});
	}
}